import React from 'react';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/fold-out-animation.css';
import AwesomeSlider from 'react-awesome-slider';
import styled from 'styled-components';
import photo1 from '../assets/images/2.jpg';
import photo2 from '../assets/images/2.jpg';
import photo3 from '../assets/images/3.jpg';
import photo4 from '../assets/images/4.jpg';
import photo5 from '../assets/images/5.jpg';
import photo6 from '../assets/images/6.jpg';
import photo7 from '../assets/images/7.jpg';
import photo8 from '../assets/images/8.jpg';
import {Instagram} from "@mui/icons-material";
import ImageGallery from "react-image-gallery";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const WrapperBlock = styled.div`
    //height: fit-content;
    height: 400px;
    //background: #d1cfd1;
    background: #ffffff;
    margin-top: -70px;
    padding-top: 100px;
`;

const StyledImg = styled.div`
    width: 280px;
    margin: 15px;

    img {
        width: 100%;
        cursor: pointer;
    }
`;

const Gallery = () => {
    const [galleries] = React.useState([
        photo1,
        photo2,
        photo3,
        photo4,
        photo5,
        photo6,
        photo7,
        photo8,
    ]);

    const images = [
        {original: photo1},
        {original: photo2},
        {original: photo3},
        {original: photo4},
        {original: photo5},
        {original: photo6},
        {original: photo7},
        {original: photo8},
    ]

    console.log('galleries>', galleries);

    return (
        <WrapperBlock>
            <h3>Наши работы</h3>
            <div className="w50">

            </div>
        </WrapperBlock>
    );
};

export default Gallery;

import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import imageSrc from '../assets/images/main.JPG'
import ornamentKz from '../assets/images/kazakh-ornaments2.png'
// import ScrollReveal from 'scrollreveal';

const WrapperBlock = styled.div`
    height: fit-content;
    background: #ebe9e9;
    margin-top: -80px;
    padding-top: 100px;
    width: 100%;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    font-family: 'Philosopher', serif;
    transition: all ease-in-out .2s;

    @media screen and (min-width: 280px) {
        height: fit-content;
        padding: 150px 5px 50px;
    }
    
    
    @media screen and (min-width: 320px) {
        height: fit-content;
        padding: 150px 5px 50px;
    }

    @media screen and (min-width: 481px) {
        //height: 712px;
        height: fit-content;
        padding-top: 150px;
        padding-bottom: 50px;
    }

    @media screen and (min-width: 769px) {
        height: fit-content;
        padding-top: 170px;
    }

    @media screen and (min-width: 1025px) {
      height: 840px;  
    }

    @media screen and (min-width: 1401px) {
        height: 840px;
    }
`

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    @media screen and (min-width: 320px) {
        flex-direction: column-reverse;
    }
    
    @media screen and (min-width: 400px) {
        flex-direction: column-reverse;
    }

    @media screen and (min-width: 481px) {
        flex-direction: column-reverse;
    }

    @media screen and (min-width: 769px) {
        width: 85%;
        flex-direction: row;
    }

    @media screen and (min-width: 1025px) {
        width: 80%;
    }

    @media screen and (min-width: 1401px) {
        width: 65%;
    }

    @media screen and (min-width: 1701px) {
        width: 53%;
    }
    
    @media screen and (min-width: 2160px) {
        width: 50%;
    }
`

const WrapperImage = styled.div`
    width: 408px;
    position: relative;
    border-radius: 15px;
    background: #726e6a;
    height: fit-content;
    overflow: hidden;
    margin-top: -32px;
    margin-right: 31px;
    border: 8px solid white;
    opacity: 0;
    transform: rotate(5deg) translateX(226px);
    transition: all 1.5s ease-in-out; /* Плавное появление */
    
    &.visible-title{
        transform: translateX(0) rotate(5deg);
        opacity: 1;
    }
    
    .uzorup {
        filter: brightness(0) invert(1) contrast(99%);
        transform: rotate(136deg);
        opacity: .3;
    }
    .uzordown{
        filter: brightness(0) invert(1) contrast(99%);
        transform: rotate(-42deg);
        opacity: .3;
    }

    @media screen and (min-width: 320px) {
        height: fit-content;
        width: 190px;
        margin-top: 20px;
        transform: none;
        border-width: 2px;
        .uzorup{
           display: none;
        }
        .uzordown{
           display: none;
        }
    }
    
    @media screen and (min-width: 400px) {
        margin-top: 20px;
        height: fit-content;
        transform: none;
        border-width: 2px;
    }

    @media screen and (min-width: 481px) {
        width: 237px;
        transform: none;
        border-width: 2px;
    }

    @media screen and (min-width: 769px) {
        width: 260px;
        height: fit-content;
        transform: rotate(5deg) translateX(226px);
        border: 4px solid white;
        .uzorup{
            display: block;
            top: -26px;
            width: 26%;
            right: -25px;
            transform: rotate(136deg);
        }
        .uzordown{
            display: block;
            width: 57%;
            bottom: -65px;
            left: -66px;

        }
    }

    @media screen and (min-width: 1025px) {
        width: 408px;
        border: 8px solid white;
    }

    @media screen and (min-width: 1401px) {

    }
`
// --------------------------------------------------------------------- TITLE
const WrapperTitle = styled.div`
    height: 413px;
    width: 410px;
    padding-top: 15px;
    font-size: 24px;
    font-family: 'Philosopher', serif;
    font-weight: 400;
    font-style: normal;
    opacity: 0;
    transform: translateY(-100px); /* Плавное появление */
    transition: all 1.5s ease-in-out; /* Плавное появление */

    
    &.visible-title{
        transform: translateY(1px); /* Плавное появление */
        opacity: 1;
    }
    & .title-sm {
        margin-top: 20px;
        font-size: 16px;
    }

    & .action-order {
        margin-top: 50px;
        font-size: 18px;
        font-family: Montserrat, sans-serif;
        background: grey;
        width: 140px;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        border-radius: 15px;
        padding: 8px;
        color: white;
        cursor: pointer;
        transition: all ease-in-out .2s;
    }

    & .action-order:hover {
        background: #c4c0c0;
        color: grey;
    }

    @media screen and (min-width: 320px) {
        font-size: 20px;
        width: 380px;
        margin-top: 5px;
        height: fit-content;
        justify-content: start;
    }

    @media screen and (min-width: 481px) {
        font-size: 21px;
        width: 310px;
        margin-top: 9px;
    }

    @media screen and (min-width: 769px) {
        width: 460px;
        margin-right: 14px;
        padding-top: 0;
        font-size: 20px;
    }

    @media screen and (min-width: 1025px) {
        width: 410px;
        padding-top: 15px;
        font-size: 24px;
    }

    @media screen and (min-width: 1401px) {

    }
`



const MainSlider = () => {

    const [isVisible, setIsVisible] = React.useState(false);
    const blockRef = useRef(null);


    useEffect(()=>{
        const handleScroll = () => {
            if (blockRef.current) {
                const top = blockRef.current.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                if (top > -650) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            }
        };


       setTimeout(()=>{ setIsVisible(true)},[500])

        document.getElementById('root').addEventListener('scroll',handleScroll)

        return ()=>{
            document.getElementById('root').removeEventListener('scroll',handleScroll)
        }
    },[])


    return (
        <WrapperBlock ref={blockRef} className="d-flex flex-row justify-content-center position-relative" id="main">
            <Container className="block-container">
                <WrapperTitle className={`title ${isVisible && 'visible-title'}`}>
                    <div>Мы Анель и Махаббат,</div>
                    <div>фотографы и создатели</div>
                    <div>первой домашней студии</div>
                    <div>в Астане - 31 flat</div>
                    <div>С нами не придется позировать.</div>
                    <div>Наша цель - показать ваши искренние чувства и эмоции.</div>
                </WrapperTitle>

                <WrapperImage className={`${isVisible && 'visible-title'}`}>
                    <img src={ornamentKz} className="uzorup position-absolute" alt=""/>
                    <img src={ornamentKz} className="uzordown position-absolute" alt=""/>
                    <img src={imageSrc} alt="" className="w-100 h-auto"/>
                </WrapperImage>
            </Container>
        </WrapperBlock>
    );
};

export default MainSlider;


//
// <WrapperTitle className="title">
//     <div className="title">
//         Ваши моменты – наша магия.
//     </div>
//     <div className="title-sm">
//         Переживайте их снова и снова.
//     </div>
//     <div className="action-order">
//         Записаться
//     </div>
// </WrapperTitle>
import React from 'react';
import styled from 'styled-components';
import studioImageSrc from '../assets/images/studio.jpg';
import iconSocialSrc from '../assets/images/social.png';
import iconLocationSrc from '../assets/images/location.png';
import iconTimeSrc from '../assets/images/time.png';

const WrapperBlock = styled.div`
    padding: 152px 20px 165px 20px;
    height: fit-content;
    position: relative;
    margin-top: -63px;
    background-color: #f9f9f9;
    text-align: center;
    font-family: 'Philosopher', serif;
    display: flex;
    justify-content: center;
    z-index: 2;
`;

const Container = styled.div`
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 320px) {

    }

    @media screen and (min-width: 400px) {

    }

    @media screen and (min-width: 481px) {

    }

    @media screen and (min-width: 769px) {
        width: 90%;
    }

    @media screen and (min-width: 1025px) {
        width: 75%;
    }

    @media screen and (min-width: 1401px) {

    }

    @media screen and (min-width: 1401px) {

    }

    @media screen and (min-width: 1701px) {

    }

    @media screen and (min-width: 2160px) {

    }
`

const Title = styled.h2`
    margin-bottom: 20px;
`;

const UpBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .description {
        font-size: 22px;
        width: 400px;
    }

    .line {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
        border-bottom: 1px solid grey;
    }

    .item-bind {
        margin-bottom: 10px;
    }

    @media screen and (min-width: 320px) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        
        .description{
            font-size: 20px;
        }
    }

    @media screen and (min-width: 400px) {
        flex-direction: column-reverse;
    }

    @media screen and (min-width: 481px) {
        flex-direction: column-reverse;
        .description{
            font-size: 22px;
        }
    }

    @media screen and (min-width: 769px) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    @media screen and (min-width: 1025px) {
        flex-direction: row;
        justify-content: space-between;
    }

    @media screen and (min-width: 1401px) {

    }

    @media screen and (min-width: 1401px) {

    }

    @media screen and (min-width: 1701px) {

    }

    @media screen and (min-width: 2160px) {

    }
`
const DownBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 75px;
    width: 100%;

    .down-item {
        position: relative;
        width: 320px;
        height: 120px;
        background: #a3a6a924;
        padding: 10px;
        font-size: 18px;

        .icon {
            width: 50px;
            height: 50px;
            top: -20px;
            left: 32px;
            overflow: hidden;
            background: rgb(179 214 255 / 61%);
            border-radius: 3px;
            padding: 6px;

            img {
                width: 100%;
                opacity: .8;
            }
        }
    }

    @media screen and (min-width: 320px) {

    }

    @media screen and (min-width: 400px) {

    }

    @media screen and (min-width: 481px) {

    }

    @media screen and (min-width: 769px) {
        .down-item{
            width: 190px;
            height: 120px;
            padding: 5px 5px 5px 10px;
            font-size: 18px;

            .icon{
                width: 38px;
                height: 38px;
                top: -20px;
                left: -12px;
            }
        }
    }

    @media screen and (min-width: 1025px) {
        .down-item{
            width: 220px;
            .icon{
                width: 40px;
                height: 40px;
                top: -30px;
                left: 10px;
            }
        }

    }

    @media screen and (min-width: 1401px) {
        .down-item{
            width: 320px;
            .icon{
                width: 50px;
                height: 50px;
                top: -20px;
                left: 32px;
            }
        }
    }

    @media screen and (min-width: 1401px) {

    }

    @media screen and (min-width: 1701px) {
        width: 85%;
    }

    @media screen and (min-width: 2160px) {

    }
`
const WrapperImage = styled.div`
    height: 340px;
    width: 340px;
    position: relative;
    border-radius: 230px;
    overflow: hidden;
    //background: url("../assets/images/studio.jpg");
    //background-size: cover;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    @media screen and (min-width: 320px) {
        height: 190px;
        width: 190px;
        margin-bottom: 30px;
    }

    @media screen and (min-width: 400px) {
        height: 200px;
        width: 200px;
        margin-bottom: 28px;
    }

    @media screen and (min-width: 481px) {
        height: 250px;
        width: 250px;
        border-radius: 50%;
    }
    
    @media screen and (min-width: 767px) {
        height: 250px;
        width: 250px;
        border-radius: 50%;
        margin-bottom: 24px;
    }
    
    @media screen and (min-width: 1025px) {
        height: 340px;
        width: 340px;
    }
`

const ContactLinks = styled.div`
    a {
        display: inline-block;
        margin: 0 10px;
        padding: 10px 20px;
        border-radius: 20px;
        color: white;
        text-decoration: none;
        transition: background-color 0.3s ease-in-out;
    }
`;

const WhatsAppLink = styled.a`
    background-color: #25D366;
    text-decoration: none;
    color: white;
    padding: 7px;

    &:hover {
        background-color: #128C7E;
    }
`;

const InstagramLink = styled.a`
    background-color: #E1306C;
    text-decoration: none;
    color: white;
    padding: 7px;

    &:hover {
        background-color: #C13584;
    }
`;

const ContactUs = () => {
    const whatsappLink = `https://wa.me/77059727904?text=${encodeURIComponent('Здравствуйте! Я хотел(а) бы записаться на фотосессию. Связался(ась) с вами через ваш сайт.')}`;
    const instagramLink = "https://instagram.com/your_username"; // Замените на ваш username в Instagram

    return (
        <WrapperBlock id="contact-us">
            <Container className="container-block">
                <UpBlock className="up-block">
                    <div>
                        <Title className="title">СВЯЖИТЕСЬ С НАМИ</Title>
                        <div className="description">
                            Мы здесь, чтобы запечатлеть ваши уникальные моменты и показать вашу красоту миру!
                        </div>
                        <div className="line"></div>
                        <div className="phone item-bind fs-20">
                            <span className="fw600">Телефон:</span> <a className="text-decoration-none color-black"
                                                                       href="tel:+77750002506">8(775) 000 25 06</a>
                        </div>
                        <div className="phone item-bind fs-20">
                            <span className="fw600">Email:</span> <a className="text-decoration-none color-black"
                                                                     href="mailto:info@31flat.kz">info@31flat.kz</a>
                        </div>
                        <div className="item-bind fs-20">
                            <WhatsAppLink href={whatsappLink} target="_blank">
                                Написать в WhatsApp
                            </WhatsAppLink>
                        </div>
                        <div className="item-bind fs-20">
                            <InstagramLink href={instagramLink} target="_blank">
                                Написать в Instagram
                            </InstagramLink>
                        </div>
                    </div>
                    <WrapperImage>
                        <img src={studioImageSrc}/>
                    </WrapperImage>
                </UpBlock>
                <DownBlock>
                    <div className="down-item">
                        Социальные сети
                        <div className="icon position-absolute">
                            <img src={iconSocialSrc}/>
                        </div>
                        <div>
                            <div className="fs-16">Instagram</div>
                            <div className="fs-16">Tik tok</div>
                            <div className="fs-16">Facebook</div>
                        </div>
                    </div>
                    <div className="down-item">
                        Локация
                        <div className="icon position-absolute">
                            <img src={iconLocationSrc}/>
                        </div>
                        <div className="fs-16">
                            г.Астана, пр.Кабанбай батыра 17, студия "31Flat"
                        </div>
                    </div>
                    <div className="down-item">
                        Часы работы
                        <div className="icon position-absolute">
                            <img src={iconTimeSrc}/>
                        </div>
                        <div>
                            <div className="fs-16">Пн – Пт …… 10:00 – 18:00,</div>
                            <div className="fs-16">Сб, Вс …… 11:00 – 20:00</div>
                        </div>
                    </div>
                </DownBlock>
            </Container>
        </WrapperBlock>
    );
};

export default ContactUs;

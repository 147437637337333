import './App.css';
import Header from "./Components/Header";
import MainSlider from "./Components/MainSlider";
import Services from "./Components/Services";
import About from "./Components/About";
import Footer from "./Components/Footer";
import Gallery from "./Components/Gallery";
import Review from "./Components/Review";
import ContactUs from "./Components/ContactUs";
import Order from "./Components/Order";
import Wework from "./Components/Wework";
import {useEffect, useRef, useState} from "react";

function App() {
    const appRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            console.log('scroll');
        };

        if (appRef.current) {
            appRef.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (appRef.current) {
                appRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);



    return (
        <div className="App"  ref={appRef}>
            <Header/>
            <MainSlider/>
            <Services/>
            <Wework/>
            <Order />
            <Review/>
            <ContactUs />
            <Footer/>
        </div>
    );
}

export default App;

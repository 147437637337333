import React from 'react';
import styled from "styled-components";

const WrapperBlock = styled.div`
    height: 800px;
    background: #ebe9e9;
    margin-top: -86px;
    padding-top: 120px;
    //clip-path: polygon(0% 0%, 100% 0, 100% 94%, 50% 100%, 0 94%);
`


const About = () => {
    return (
        <WrapperBlock>
            <h3> - Раздел -</h3>
        </WrapperBlock>
    );
};

export default About;

import React from 'react';
import styled from "styled-components";


const WrapperBlock = styled.div`
    height: 450px;
    margin-top: -4px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: 320px) {

    }

    @media screen and (min-width: 400px) {

    }

    @media screen and (min-width: 481px) {

    }

    @media screen and (min-width: 769px) {

    }

    @media screen and (min-width: 1025px) {

    }

    @media screen and (min-width: 1401px) {

    }

    @media screen and (min-width: 1401px) {

    }

    @media screen and (min-width: 1701px) {

    }

    @media screen and (min-width: 2160px) {

    }
`

const Container = styled.div`
    display: flex;
    margin-top: 70px;
    width: 70%;
    justify-content: space-between;
    
    .footer-item .title{
        margin-bottom: 28px;
    }
`


const Footer = () => {
    return (
        <WrapperBlock>
            <Container className="d-flex flex-xl-row flex-md-row flex-sm-column">
                <div className="footer-item">
                    <div className="title fs-22">31Flat</div>
                    <div className="content">8 (775) 000 25 06 </div>
                    <div className="content">info@31flat.com</div>
                    <div className="content">🇰🇿 Kazakhstan, Astana </div>


                </div>
                <div className="footer-item">
                    <div className="title fs-22">Навигация по сайту</div>
                    <div className="content d-flex flex-column list-style-type-none align-items-lg-start">
                        <li className="mx-3">Главная</li>
                        <li className="mx-3">Фотосессии</li>
                        <li className="mx-3">Как мы работаем</li>
                        <li className="mx-3">Отзывы</li>
                        <li className="mx-3">Контакты</li>
                    </div>
                </div>
                <div className="footer-item">
                    <div className="fs-22">Следите за нами </div>
                    <div className="title fs-22">в социальных сетях </div>
                    <div className="content">Инстаграм</div>
                    <div className="content">Тик ток</div>
                </div>
            </Container>
            <div className="copyright fs-18 mb-20">
                © 2024 Все права защищены. <span className="fs-18">🩶</span> 31Flat. г.Астана
            </div>
        </WrapperBlock>
    );
};

export default Footer;

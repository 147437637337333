import React from 'react';
import styled from "styled-components";
import {TextField} from "@mui/material";


const WrapperBlock = styled.div`
    height: fit-content;
    padding-top: 35px;
    padding-bottom: 20px;
    background: none;
`

const StyledContainer = styled.div`

    @media screen and (min-width: 320px) {
        
    }
    @media screen and (min-width: 400px) {
        
    }
    @media screen and (min-width: 481px) {
        width: 90%;
    }

    @media screen and (min-width: 769px) {
        width: 85%;
    }

    @media screen and (min-width: 1025px) {
        width: 95%;
    }

    @media screen and (min-width: 1401px) {
        width: 85%;
    }

    @media screen and (min-width: 1701px) {
        width: 72%;
    }

    @media screen and (min-width: 2160px) {
        width: 65%;
    }
`

const Title = styled.div`
    color: white;
    background: #0000006e;
    font-size: 35px;
    width: 60%;
    @media screen and (min-width: 320px) {
        font-size: 28px;
    }
    @media screen and (min-width: 769px) {
        font-size: 32px;
    }
    @media screen and (min-width: 1025px) {
        font-size: 35px;
    }
`

const StyledTextField = styled(TextField)`
    &.MuiFormControl-root{
        margin-right: 5px;
    }
    & .MuiFormLabel-root.MuiInputLabel-root {
        color: white;
    }

    & .MuiInputBase-root {
        width: 300px;
        color: white;

    }

    & .MuiInputBase-root fieldset {
        border-color: white;
    }
    
`

const StyledButton = styled.div`
    width: 112px;
    height: 45px;
    background: #1499c1;
    padding: 9px;
    border-radius: 8px;
    cursor: pointer;
    color: white;
    margin-top: 27px;
    transition: all ease-in-out .2s;
    font-size: 18px;

    &:hover {
        background: #38a1c0;
        font-size: 19px;
        padding: 7px;
    }
`

const SectionFields = styled.div`
    display: flex;
    flex-direction: row;

    @media screen and (min-width: 320px) {
        flex-direction: column;
    }
    @media screen and (min-width: 400px) {

    }
    
    @media screen and (min-width: 481px) {
        flex-direction: column;
    }

    @media screen and (min-width: 769px) {
        flex-direction: column;
    }

    @media screen and (min-width: 1025px) {
        flex-direction: row;
    }

    @media screen and (min-width: 1401px) {

    }

    @media screen and (min-width: 1701px) {

    }

    @media screen and (min-width: 2160px) {
   
    }
`

const Order = () => {
    return (
        <WrapperBlock className="d-flex justify-content-center" id="order">
            <StyledContainer className="d-flex flex-column justify-content-center align-items-center">
                <Title>Запишитесь на фотосессию прямо сейчас</Title>
                <SectionFields className="section-fields">
                    <StyledTextField
                        id="outlined-basic"
                        label="Имя"
                        variant="outlined"
                    />
                    <StyledTextField
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                    />
                    <StyledTextField
                        id="outlined-basic"
                        label="Телефон"
                        type="number"
                        variant="outlined"
                    />
                </SectionFields>
                <StyledButton>
                    Записаться
                </StyledButton>
            </StyledContainer>
        </WrapperBlock>
    );
};

export default Order;

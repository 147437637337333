import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import image1 from "../assets/images/service1.JPEG";
import image2 from "../assets/images/service2.JPEG";
import image3 from "../assets/images/service3.JPEG";
import image4 from "../assets/images/service4.JPEG";
import image5 from "../assets/images/service5.JPEG";

const Block = styled.div`
    height: fit-content;
    background: #ffffff;
    margin-top: -50px;
    padding-top: 80px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    transition: all ease-in-out .2s;

    @media screen and (min-width: 481px) {

    }

    @media screen and (min-width: 769px) {

    }

    @media screen and (min-width: 1025px) {
        height: 1040px;
    }

    @media screen and (min-width: 1401px) {
        height: 1470px;
    }
`

const StyledContainer = styled.div`
    opacity: 0;
    transform: translateY(-100px); /* Плавное появление */
    transition: all 1.5s ease-in-out; /* Плавное появление */

    &.show-block {
        opacity: 1;
        transform: translateY(0px);
    }

    @media screen and (min-width: 481px) {
        width: 90%;
    }

    @media screen and (min-width: 769px) {
        width: 85%;
    }

    @media screen and (min-width: 1025px) {
        width: 95%;
    }

    @media screen and (min-width: 1401px) {
        width: 85%;
    }

    @media screen and (min-width: 1701px) {
        width: 72%;
    }
`

const Card = styled.div`
    width: 300px;
    height: 500px;
    margin: 10px;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out .2s;
    position: relative;


    @media screen and (min-width: 481px) {
        width: 280px;
        height: 396px;
        margin: 7px;
        margin-bottom: 10px;
    }

    @media screen and (min-width: 769px) {
        width: 281px;
        height: 484px;
        margin: 10px;
        margin-bottom: -10px;
    }

    @media screen and (min-width: 1025px) {
        width: 270px;
        height: 406px;
        margin: 16px
    }

    @media screen and (min-width: 1401px) {
        width: 340px;
        height: 600px;
        margin: 25px;
    }
    
    @media screen and (min-width: 2160px) {
        width: 390px;
        height: 600px;
        margin: 25px;
    }    
`

const Title = styled.div`
    //font-family: Forum, sans-serif;
    font-size: 18px;
    position: relative;
    font-family: 'Philosopher', serif;
    
    @media screen and (min-width: 320px) {
        font-size: 23px;
        position: absolute;
        bottom: 10px;
        color: white;
        background: #797676;
    }
    
    @media screen and (min-width: 481px) {
        font-size: 23px;
        position: absolute;
        bottom: 10px;
        color: white;
        background: #797676;
    }
    
    @media screen and (min-width: 769px) {
        position: relative;
        background: none;
        color: black;
    }

    @media screen and (min-width: 1025px) {

    }

    @media screen and (min-width: 1401px) {

    }
`
const WrapperImage = styled.div`
    overflow: hidden;
    border-radius: 5px;
`




const Services = () => {

    const [list] = React.useState([
        {type: 'individual', src: image2, title: 'Индивидуальные съёмки'},
        {type: 'story', src: image4, title: 'Love Story'},
        {type: 'birthday', src: image5, title: 'Фото-прогулки'},
        {type: 'family', src: image1, title: 'Семейные фотосессии'},
        {type: 'pregnancy', src: image3, title: 'Беременность'},
    ])

    const [isVisible, setIsVisible] = React.useState(false);
    const blockRef = useRef(null);


    useEffect(()=>{
        const handleScroll = () => {
            if (blockRef.current) {
                const top = blockRef.current.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                // console.log('top2', top)
                if (top > -1200) {
                    setIsVisible(true);
                } else {
                    // setIsVisible(false);
                }
            }
        };


        setTimeout(()=>{ setIsVisible(true)},[500])

        document.getElementById('root').addEventListener('scroll',handleScroll)

        return ()=>{
            document.getElementById('root').removeEventListener('scroll',handleScroll)
        }
    },[])


    return (
        <Block className="block w-100" ref={blockRef} id="fotosession">
            <StyledContainer className={`block-container ${isVisible && 'show-block'}`}>
                <h3 className="mt-10 mb-20">Фотосессии</h3>
                <div className="cards d-flex flex-wrap justify-content-center">
                    {
                        list.map((item, index) => (
                            <Card key={index} className="card-item d-flex flex-column">
                                <WrapperImage className="w-100 mb-10 pointer mx-15">
                                    <img src={item?.src} alt="" className="w-100"/>
                                </WrapperImage>
                                <Title className="pointer">{item?.title}</Title>
                            </Card>
                        ))
                    }
                </div>
            </StyledContainer>
        </Block>
    );
};

export default Services;

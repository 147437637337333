import React from 'react';
import styled from "styled-components";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

const WrapperBlock = styled.div`
    height: fit-content;
    background: #ebe9e9;
    clip-path: polygon(0% 0%, 100% 0, 100% 94%, 50% 100%, 0 94%);
    padding-bottom: 92px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 320px) {
        clip-path: polygon(0% 0%, 100% 0, 100% 99%, 50% 100%, 0 99%);
    }

    @media screen and (min-width: 400px) {
        clip-path: polygon(0% 0%, 100% 0, 100% 98%, 50% 100%, 0 98%);
    }

    @media screen and (min-width: 481px) {

    }

    @media screen and (min-width: 769px) {
        clip-path: polygon(0% 0%, 100% 0, 100% 97%, 50% 100%, 0 97%);
    }

    @media screen and (min-width: 1025px) {
        clip-path: polygon(0% 0%, 100% 0, 100% 96%, 50% 100%, 0 96%);
    }

    @media screen and (min-width: 1401px) {
        clip-path: polygon(0% 0%, 100% 0, 100% 94%, 50% 100%, 0 94%);
    }

    @media screen and (min-width: 1401px) {

    }

    @media screen and (min-width: 1701px) {

    }

    @media screen and (min-width: 2160px) {

    }
    
`
const Container = styled.div`
    width: 80%;
`

const WrapperAccordion = styled.div`
    margin: 0 auto;
    @media screen and (min-width: 320px) {
        width: 100%;
    }

    @media screen and (min-width: 400px) {
        width: 100%;
    }

    @media screen and (min-width: 481px) {
        width: 90%;
    }

    @media screen and (min-width: 769px) {
        width: 85%;
    }

    @media screen and (min-width: 1025px) {
        width: 100%;
    }

    @media screen and (min-width: 1401px) {
        width: 70%;
    }
    

    @media screen and (min-width: 1701px) {
        width: 68%;
    }

    @media screen and (min-width: 2160px) {
        width: 65%;
    }

`

const WrapperItems = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
    
    @media screen and (min-width: 320px) {
        width: 87%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 670px;
    }

    @media screen and (min-width: 400px) {
        width: 87%;
        height: 670px;
    }

    @media screen and (min-width: 481px) {
        width: 100%;
        height: 670px;
    }

    @media screen and (min-width: 769px) {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        height: fit-content;
    }

    @media screen and (min-width: 1025px) {
        width: 90%;
    }

    @media screen and (min-width: 1401px) {
        width: 87%;
    }

    @media screen and (min-width: 1701px) {
        width: 87%;
    }

    @media screen and (min-width: 2160px) {

    }
`

const StyledItem = styled.div`
    background: #ffffff;
    width: 400px;
    padding: 15px;
    border-bottom-right-radius: 95px;
    border-top-left-radius: 95px;

    @media screen and (min-width: 320px) {
        width: 180px;
        margin-bottom: 15px;
    }

    @media screen and (min-width: 400px) {
        width: 200px;
    }

    @media screen and (min-width: 481px) {
        width: 250px;
        .item-id {
            top: -6px;
        }
    }

    @media screen and (min-width: 769px) {
        width: 300px;
        padding: 30px 5px;
        margin: 20px;
        .item-id {
            top: 7px;
        }
    }

    @media screen and (min-width: 1025px) {
        width: 250px;
        padding: 15px;
    }

    @media screen and (min-width: 1401px) {
        width: 300px;
    }

    @media screen and (min-width: 1701px) {
        width: 400px;
    }

    @media screen and (min-width: 2160px) {

    }
`
const StyledItemId = styled.div`
    font-size: 41px;
    width: 50px;
    height: 50px;
    top: 7px;
    left: 5px;
    font-weight: 500;
    background: aliceblue;
    border-radius: 24px;
    text-align: center;
    align-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Wework = () => {
    const list = [
        {
            id: '1',
            icon: <EditCalendarIcon className="fs-20 pointer"/>,
            type: 'Запись',
            text: 'Оставьте заявку'
        },
        {
            id: '2',
            icon: <VoiceChatIcon className="fs-20 pointer"/>,
            type: 'Подготовка к съемке ',
            text: 'Наши специалисты помогут вам подготовиться к фотосессии.'},
        {
            id: '3',
            icon: <PhotoCameraIcon className="fs-20 pointer"/>,
            type: 'Фотосессия и результат',
            text: 'Вы получите профессиональные снимки, которые будут доступны в течение несколькихдней.'
        },
    ]
    return (
        <WrapperBlock className="we-work-blokc" id="wework">
            <Container>
                <div className="font-philosopher fs-22 mt-20 mb-10">
                    Как мы работаем
                </div>
                <WrapperItems className="wrapper-items">
                    {
                        list.map((item, index) => (
                            <StyledItem key={index} className="position-relative">
                                <StyledItemId className="position-absolute item-id">{item.id}</StyledItemId>
                                <div className="">{item.icon}</div>
                                <div className="fs-22">{item.type}</div>
                                <div className="fs-18 pt-1">{item.text}</div>
                            </StyledItem>
                        ))
                    }
                </WrapperItems>
                <div className="font-philosopher fs-22 mt-10 mb-10">
                    Частые вопросы
                </div>
                <WrapperAccordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Какие лучшие места для съемки?
                        </AccordionSummary>
                        <AccordionDetails>
                            Мы предлагаем несколько вариантов мест в зависимости от вашего стиля и предпочтений. Это может
                            быть парк, исторический район города, уютное кафе или ваше любимое место в природе.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            Какие лучше одежду выбрать для фотосессии?
                        </AccordionSummary>
                        <AccordionDetails>
                            Рекомендуем выбирать одежду, в которой вы чувствуете себя комфортно и которая подчеркивает ваш
                            стиль. Для групповых съемок важно согласовать цвета и стили одежды с другими участниками съемки.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel3-content"
                            id="panel3-header"
                        >
                            Как долго будет длиться съемка и когда можно ожидать результаты?
                        </AccordionSummary>
                        <AccordionDetails>
                            Время съемки зависит от выбранного пакета и локации. Обычно съемка длится от 1 до 2 часов.
                            Результаты обработки фотографий будут доступны в течение 1-2 недель после съемки, в зависимости
                            от загруженности фотографа и объема материалов.
                        </AccordionDetails>
                    </Accordion>
                </WrapperAccordion>
            </Container>
        </WrapperBlock>
    );
};

export default Wework;

import React, {useEffect} from 'react';
import styled from "styled-components";
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';

const WrapperBlock = styled.div`
    position: relative;
    height: fit-content;
    padding-top: 70px;
    padding-bottom: 100px;
    background: white;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    clip-path: polygon(50% 7%, 100% 0, 100% 100%, 0 100%, 0 0);
    z-index: 4;
    clip-path: polygon(50% 1%, 100% 0, 100% 100%, 0 100%, 0 0);
    @media screen and (min-width: 320px) {
        clip-path: polygon(50% 1%, 100% 0, 100% 100%, 0 100%, 0 0);
    }

    @media screen and (min-width: 400px) {
        clip-path: polygon(50% 2%, 100% 0, 100% 100%, 0 100%, 0 0);
    }

    @media screen and (min-width: 481px) {

    }

    @media screen and (min-width: 769px) {
        clip-path: polygon(50% 3%, 100% 0, 100% 100%, 0 100%, 0 0);
    }

    @media screen and (min-width: 1025px) {
        clip-path: polygon(50% 4%, 100% 0, 100% 100%, 0 100%, 0 0);
    }

    @media screen and (min-width: 1401px) {
        clip-path: polygon(50% 6%, 100% 0, 100% 100%, 0 100%, 0 0);
    }

    @media screen and (min-width: 1401px) {

    }

    @media screen and (min-width: 1701px) {

    }

    @media screen and (min-width: 2160px) {

    }
`

const StyledItem = styled.div`
    text-align: center;
    word-break: break-all;
    padding: 24px;
    background: aliceblue;
    margin: 15px;
    width: 340px;
    font-size: 18px;

`


const Review = () => {

    const [clients] = React.useState([
        {name: 'Jordon Bek', text:'Искали с женой хорошего фотографа для семейной фотосессии. Обошли все фотостудии в городе — либо дорого, либо качество работы не устраивало. Увидели объявление вашей студии, позвонили, обсудили все детали, получили пример фото и стоимость. Не раздумывая записались на фотосессию! Фотографии и впечатления — шикарные!'},
        {name: 'Alex Johnson', text:'Мы с женой долго искали фотографа для свадьбы. Цены в большинстве студий были заоблачными, а качество работы не всегда соответствовало нашим ожиданиям. Когда мы увидели портфолио вашей студии, были приятно удивлены. Сразу же связались с вами и не пожалели ни на секунду! Результат просто потрясающий!'},
        {name: 'Emily Williams', text:'Случайно наткнулись на вашу студию в интернете и были поражены качеством и креативностью ваших работ. Решили не откладывать в долгий ящик и записались на фотосессию. От процесса съемки до получения готовых фотографий все прошло на высшем уровне. Результат превзошел наши ожидания!'},
        {name: 'Daniel Brown', text:'Друзья настоятельно рекомендовали вашу студию для семейной фотосессии. Мы не колеблясь обратились к вам и были приятно удивлены профессионализмом и творческим подходом. Организация съемки была на высшем уровне, а результаты поразили своей красотой и искренностью.'},
        {name: 'Sophia Clark', text:'Я долго искали фотографа для первой фотосессии нашего малыша. Вашу студию посоветовали нам знакомые. Результаты работы и внимание к деталям превзошли все наши ожидания. Спасибо вам за теплые и нежные фотографии, которые станут важным воспоминанием на всю жизнь.'},
        {name: 'Olivia Davis', text:'Обнаружили вашу студию через социальные сети и были поражены вашими творческими работами. Решились на фотосессию и не могли быть счастливее с результатом. Профессионализм, комфортная атмосфера и качество фотографий — всё на высшем уровне.'},
    ])
    useEffect(() => {
        const splide = new Splide('.splide', {
            type: 'loop',
            perPage: 3,
            perMove: 1,
        });

        splide.mount();
    }, []);


    return (
        <WrapperBlock id="review">
            <h3>Отзывы о нас</h3>
            <h5>
                Довольные клиенты делятся впечатлениями
            </h5>
            <div className="splide">
                <div className="splide__track">
                    <ul className="splide__list">
                        {
                            clients.map((client, index) => (
                                <StyledItem key={index} className="splide__slide">
                                    <div>{client.text}</div>
                                    <div></div>
                                    <div>{client.name}</div>
                                </StyledItem>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </WrapperBlock>
    );
};

export default Review;

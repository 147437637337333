import React from 'react';
import styled from "styled-components";

const StyledSpan = styled.div`
    font-family: 'Roboto',serif;
    font-weight: 400;
    z-index: 9;
    width: 142px;
    background: aliceblue;
`
const QuadroStyled = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.55);
    width: 40px;
    height: 40px;
    z-index: 8;
    left: -7px;
    top: 17px;
    border-radius: 10px;
`

const Logo = (args) => {
    return (
        <div {...args} className="align-content-center position-relative">
            {/*<QuadroStyled className="position-absolute">*/}

            {/*</QuadroStyled>*/}
            <StyledSpan className="fs-3">31 FLAT</StyledSpan>
        </div>
    );
};

export default Logo;

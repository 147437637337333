import React, {useState} from 'react';
import Logo from "./Logo";
import styled from "styled-components";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

const WrapperContainer = styled.div`
    height: 80px;
    z-index: 99999;
    top: 0;
    background: white;
    position: fixed;
    font-family: 'Roboto', sans-serif;
    box-shadow: 2px 4px 5px 0 rgba(209, 209, 209, 1);
    transition: all ease-in-out .3s;

    & .phone-number {
        cursor: pointer;
    }
    @media screen and (min-width: 300px) {
        height: 62px;
    }

    @media screen and (min-width: 600px) {
        height: 80px;
    }
`

const StyledContainer = styled.div`
    position: relative;
    transition: all ease-in-out .2s;

    @media screen and (min-width: 320px) {
        width: 90%;
        .burger-menu {
            display: flex;
            font-size: 45px;

            svg {
                font-size: 45px;
            }
        }

        .menu {
            display: none;
        }

        .phone-number {
            display: none !important;
        }

        justify-content: space-between;
    }

    @media screen and (min-width: 400px) {

    }

    @media screen and (min-width: 481px) {

    }

    @media screen and (min-width: 769px) {

    }

    @media screen and (min-width: 900px) {
        width: 90%;
    }

    @media screen and (min-width: 1025px) {
        width: 95%;
        .burger-menu {
            display: none;
        }

        .menu {
            display: block;
        }

        .phone-number {
            display: flex !important;
        }
    }

    @media screen and (min-width: 1401px) {
        width: 80%;
    }

    @media screen and (min-width: 1701px) {
        width: 70%;
    }

    @media screen and (min-width: 2160px) {
        width: 65%;
    }
`

const PhoneBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;

    div {
        width: 100%;
        height: 42px;
        letter-spacing: 0px;
        color: #4f4f51;
    }
`

const StyledMenu = styled.div`
    li {
        font-size: 20px;
        font-family: Montserrat, sans-serif;
        font-weight: 400;
        cursor: pointer;

        a {
            text-decoration: none;
            color: black;
            font-family: Montserrat, sans-serif;
            font-size: 20px;
        }
    }
`

const DownMenu = styled.div`
    width: 100%;
    height: 0;
    top: 80px;
    background: white;
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    transition: all ease-in-out .2s;
    overflow: hidden;
    display: flex;
    justify-content: center;

    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 30px;

        li {
            font-size: 22px;
            margin-bottom: 15px;
            cursor: pointer;

            a {
                color: #2c2f38;
                text-decoration: none;
            }
        }
    }

    &.open {
        height: 500px;
    }
    
    @media screen and (min-width: 320px){
        top: 60px;
    }
    @media screen and (min-width: 600px){
        top: 80px;
    }
    @media screen and (min-width: 1026px) {
        display: none;
    }
`


const Header = () => {

    const [open, setOpen] = useState(false)

    const handleOpenMenu = () => {
        setOpen(!open)
    }

    return (
        <WrapperContainer id="Header" className="d-flex flex-row align-content-center col-12 justify-content-center">
            <StyledContainer className="d-flex flex-row justify-content-xl-between align-content-sm-between">
                <Logo id="Logo"/>
                <StyledMenu className="menu align-content-center">
                    <ul className="d-flex flex-row list-style-type-none m-0">
                        <li className="mx-3"><a href="#main">Главная</a></li>
                        <li className="mx-3"><a href="#fotosession">Фотосессии</a></li>
                        <li className="mx-3"><a href="#wework">Информация</a></li>
                        <li className="mx-3"><a href="#review">Отзывы</a></li>
                        <li className="mx-3"><a href="#contact-us">Контакты</a></li>
                    </ul>
                </StyledMenu>
                <PhoneBlock className="phone-number">
                    <div><LocalPhoneIcon className="mr-10"/>
                        8(775)000 25 06
                    </div>
                </PhoneBlock>
                <div className="burger-menu justify-content-center align-items-center" onClick={handleOpenMenu}>
                    {
                      open ? <MenuOpenIcon className="fs-20 pointer"/> :<MenuIcon className="fs-20 pointer"/>
                    }
                </div>
            </StyledContainer>
            <DownMenu className={`down-menu position-absolute ${open && 'open'}`}>
                <ul>
                    <li className="mx-3" onClick={() => {
                        setOpen(false)
                    }}>
                        <a href="#main" onClick={() => {
                        }}>
                            Главная
                        </a>
                    </li>
                    <li className="mx-3" onClick={() => {
                        setOpen(false)
                    }}>
                        <a href="#fotosession" onClick={() => {
                        }}>
                            Фотосессии
                        </a>
                    </li>
                    <li className="mx-3" onClick={() => {
                        setOpen(false)
                    }}>
                        <a href="#wework">
                            Информация
                        </a>
                    </li>
                    <li className="mx-3" onClick={() => {
                        setOpen(false)
                    }}>
                        <a href="#order">
                            Записаться
                        </a>
                    </li>
                    <li className="mx-3" onClick={() => {
                        setOpen(false)
                    }}>
                        <a href="#review">
                            Отзывы
                        </a>
                    </li>
                    <li className="mx-3" onClick={() => {
                        setOpen(false)
                    }}>
                        <a href="#contact-us">
                            Контакты
                        </a>
                    </li>
                </ul>
            </DownMenu>
        </WrapperContainer>
    );
};

export default Header;
